import React, { useEffect } from "react";
import { useParams } from "react-router";

import logo from '../logo.png';
import profileImg from '../img/profile-img.jpg';

import {
    Link
} from "react-router-dom";


function Header() {
    let { postSlug } = useParams();

    let userSession = JSON.parse(localStorage.getItem('token'));


    useEffect(() => {
        // Fetch post using the postSlug
    }, [postSlug]);

    const logoutUser = () => {
        console.log("Logout");
        //clearToken();
        localStorage.clear();
        window.location.href = '/';
    }

    return (
        <header id="header" className="header fixed-top d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-between">
              <Link to="/dashboard" className="logo d-flex align-items-center">
              <img src={logo} className="App-logo" alt="logo" />
              <span className="d-none d-lg-block">kudosy Admin</span>
              </Link>
          </div>
          <div style={{width: "150px","paddingTop":"10px"}}>
              <Link to="/dashboard" className="dropdown-item d-flex align-items-center"><h5><span>Rewards</span></h5></Link>
          </div>
          <div style={{width: "150px","paddingTop":"10px"}}>
              <Link to="/users" className="dropdown-item d-flex align-items-center"><h5><span>Users</span></h5></Link>
          </div>
          <div style={{width: "150px","paddingTop":"10px"}}>
              <Link to="/feedback" className="dropdown-item d-flex align-items-center"><h5><span>Feedback</span></h5></Link>
          </div>
          <nav className="header-nav ms-auto">
              <ul className="d-flex align-items-center">
                  <li className="nav-item d-block d-lg-none">
                      <a className="nav-link nav-icon search-bar-toggle " href="#">
                          <i className="bi bi-search"></i>
                      </a>
                  </li>
                  <li className="nav-item dropdown pe-3">
                      <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                          <img src={profileImg} alt="Profile" className="rounded-circle" />
                          <span className="d-none d-md-block dropdown-toggle ps-2">{userSession.full_name}</span>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                          <li className="dropdown-header">
                              <h6>{userSession.full_name}</h6>
                              <span>Administrator</span>
                          </li>
                          <li>
                              <hr className="dropdown-divider" />
                          </li>
                          <li>
                              <Link to="/change-password" className="dropdown-item d-flex align-items-center"><i className="bi bi-person"></i><span>Change Passwprd</span>
                              </Link>
                          </li>
                          <li>
                              <hr className="dropdown-divider" />
                          </li>
                          <li>
                              <a className="dropdown-item d-flex align-items-center" onClick={logoutUser}>
                                  <i className="bi bi-box-arrow-right"></i>
                                  <span>Sign Out</span>
                              </a>
                          </li>
                      </ul>
                  </li>
              </ul>
          </nav>
      </header>
    );
}

export default Header;