import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

import Swal from "sweetalert2";
import * as Yup from 'yup';
import './Dashboard.css';

import 'antd/dist/antd.min.css';
import { Pagination } from 'antd';


import {
    Header,
    Footer
   
} from "../components";

//https://www.freakyjolly.com/react-bootstrap-table-next-example-pagination-filter-sorting-export-to-excel/



async function getFeedbacks(keyword) {
   
    return await fetch(process.env.REACT_APP_SERVER_BASE_URL+"get_feedback?search_by="+keyword, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => {
           return data.json()
        })
        .catch(error => {
          console.log("error","error:"+error);
          return "error:"+error;
        });
};


async function deleteFeedBack(udid) {
    return await fetch(process.env.REACT_APP_SERVER_BASE_URL+'delete_kudosy_feedback', {
            method: 'POST',
            body: JSON.stringify({"feedback_id":udid,"action":"delete_kudosy_feedback"}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json());
};



function FeedBacks() {
    const [feedback, setFeedBacks] = useState();
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(9);
    const [dataisLoaded, setDataisLoaded] = useState(true);
    let { postSlug } = useParams();
    const navigate = useNavigate();


    const validationSchema = Yup.object().shape({
        query: Yup.string().required('Please enter keyword to search..')
    });

    const { register, handleSubmit,  formState } = useForm();

    const { errors } = formState;



    useEffect(() => {
        (async () => {
            let feedbacks = await getFeedbacks('');
            if (feedbacks && feedbacks.count > 0) {
                setFeedBacks(feedbacks.items);
                setDataisLoaded(false);
            }
            else {
              setFeedBacks(false);
              setDataisLoaded(false);
            }
        })();
    }, [postSlug]);


    function handleDelete(udid) {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showDenyButton: true,
            //showCancelButton: true,
            confirmButtonText: 'Yes Delete',
            denyButtonText: `Don't Delete`,
        }).then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    if (udid && udid !== '') {
                        setDataisLoaded(true);
                        let delUser = await deleteFeedBack(udid);
                        if (delUser && delUser.status == 'success') {
                            Swal.fire('Record deleted successfully!', '', 'success');
                            let feedbacks = await getFeedbacks('');
                            if (feedbacks && feedbacks.count > 0) {
                                setFeedBacks(feedbacks.items);
                                setDataisLoaded(false);
                            } else {
                                setFeedBacks(false);
                                setDataisLoaded(false);
                            }

                        } else {
                            Swal.fire('User not deleted. Please try again', '', 'error')
                        }
                    }
                })();
            } else if (result.isDenied) {

            }
        })
    }


    function handlePageChange(value,pageSize) {
        console.log("value", value, pageSize)
        if (value <= 1) {
            setMinValue(0);
            setMaxValue(pageSize);
        } else {
            setMinValue((value - 1) * pageSize);
            setMaxValue(Number(value) * pageSize);
        }
    }

    function onSubmit(data) {
        //if (data && data.query !== '') {
            (async () => {
                setMinValue(0);
                setDataisLoaded(true);
                let feedbacks = await getFeedbacks(data.query);
                if (feedbacks && feedbacks.count > 0) {
                    setFeedBacks(feedbacks.items);
                    setDataisLoaded(false);
                } else {
                    setFeedBacks(false);
                    setDataisLoaded(false);
                }
            })();
        //}
    }

    return (
        <div>
    <Header />
    
    <main id="main" className="main">
        <div className="pagetitle">
            <h1>Dashboard</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">Feedback</li>
                </ol>
            </nav>
        </div>
        <section className="section dashboard">
            
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body">

                                  <div className="row">
                                    <div className="col-md-4"><h5 className="card-title">Manage Feedback</h5></div>
                                    <div className="col-md-4">
                                      <div className="search-bar">
                                        <form className="search-form d-flex align-items-center" onSubmit={handleSubmit(onSubmit)}>
                                          <input type="text" name="query" id="query"  {...register('query')} className={`${errors.query ? 'is-invalid' : ''}`}  placeholder="Search" title="Enter search keyword" />
                                          <button type="submit" title="Search"><i className="bi bi-search"></i></button>
                                        </form>
                                        <div className="invalid-feedback">{errors.query?.message}</div>
                                      </div>
                                    </div>
                                    
                                  </div>   

                                  <br />
                                    <table className="table table-borderless datatable">
                                        <thead>
                                            <tr>
                                                <th scope="col">Email</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Rating</th>
                                                <th scope="col">Created</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { dataisLoaded ?
                                            <tr>
                                                <td colSpan="6" align="center"><br />
                                                    <div className="spinner-grow text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-secondary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-success" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-danger" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-warning" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-info" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-light" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-dark" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            : ''
                                            }

                                          {feedback &&
                                              feedback.length > 0 &&
                                              feedback.slice(minValue, maxValue).map((item, index) => (
                                            
                                            //setCounter(counter+1)
                                            <tr key={index}>
                                                <td>{item.email}</td>
                                                <td>{item.first_name} {item.last_name}</td>
                                                
                                               
                                                <td>{item.description}</td>
                                                <td>
                                                { item.rating == 0 && 

                                                 <div></div>
                                                }

                                                { item.rating == 1 &&

                                                 <div><i className="bi bi-star-fill" style={{color:"#4153F1"}}></i></div>
                                                }
                                                { item.rating == 2 &&

                                                 <div><i className="bi bi-star-fill" style={{color:"#4153F1"}}></i> <i className="bi bi-star-fill" style={{color:"#4153F1"}}></i> </div>
                                                }
                                                { item.rating == 3 && 

                                                 <div><i className="bi bi-star-fill" style={{color:"#4153F1"}}></i> <i className="bi bi-star-fill" style={{color:"#4153F1"}}></i> <i className="bi bi-star-fill" style={{color:"#4153F1"}}></i> </div>
                                                }
                                                { item.rating == 4 &&

                                                 <div><i className="bi bi-star-fill" style={{color:"#4153F1"}}></i> <i className="bi bi-star-fill" style={{color:"#4153F1"}}></i> <i className="bi bi-star-fill" style={{color:"#4153F1"}}></i> <i className="bi bi-star-fill" style={{color:"#4153F1"}}></i></div>
                                                }
                                                { item.rating >= 5 && 

                                                 <div><i className="bi bi-star-fill" style={{color:"#4153F1"}}></i> <i className="bi bi-star-fill" style={{color:"#4153F1"}}></i> <i className="bi bi-star-fill" style={{color:"#4153F1"}}></i> <i className="bi bi-star-fill" style={{color:"#4153F1"}}></i> <i className="bi bi-star-fill" style={{color:"#4153F1"}}></i></div>
                                                }
                                                </td>
                                                <td>{item.createdAt}</td>
                                                <td><a className="btn btn-danger btn-sm" title="Delete" onClick={e => handleDelete(item.id)}><i className="bi bi-trash"></i></a></td>
                                                
                                            </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                  
                                        <div style={{ display: 'block', width: 700, padding: 30 }}>
                                          { feedback ?
                                          <Pagination defaultCurrent={1} defaultPageSize={10} onChange={handlePageChange} total={feedback.length} />
                                          : ''
                                        }
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>
    </main>
    <Footer />
</div>
    );
}

export default FeedBacks;
