import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

import {
    Header,
    Footer,
   
    useToken
} from "../components";

import { yupResolver } from '@hookform/resolvers/yup';
import Swal from "sweetalert2";
import * as Yup from 'yup';



async function updateUserExpiryDate(jsonData) {
    return fetch(process.env.REACT_APP_SERVER_BASE_URL+'update_user_expiry_date', {
            method: 'POST',
            body: JSON.stringify(jsonData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json())
}


async function getUser(credentials) {

   return fetch(process.env.REACT_APP_SERVER_BASE_URL+'get_user', {
            method: 'POST',
            body: JSON.stringify(credentials),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json())
}



function EditExpiryDate() {

    const { id } = useParams();

    // form validation rules 
    const validationSchema = Yup.object().shape({
        users_custom_expiry_date: Yup.string().required('Expiry date is required')
    });

    const { register, handleSubmit, reset, setValue, getValues, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { errors } = formState;
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [expiryDate, setExpiryDate] = useState();

     const [reward, setReward] = useState({});

    useEffect(() => {
        
        (async () => {
            if(id && id!=='') {
                let obj = {
                    "id": id,
                    "action":"get_profile"
                }
                let userinfo = await getUser(obj);
                console.log(userinfo);
                if (userinfo && userinfo.status=='success') {
                    let getItems = userinfo.data;
                    console.log(getItems['users_custom_expiry_date']);
                    setExpiryDate(getItems['users_custom_expiry_date']);
                    const fields = ['users_custom_expiry_date'];
                    fields.forEach(field => setValue(field, getItems[field]));
                }
            }
        })();
    }, []);


    function onSubmit(data) {
        setIsSubmitting(true);
        let  userSession  = JSON.parse(localStorage.getItem('token'));
        
        (async () => {
             if(data && data['users_custom_expiry_date']!=='') {
                let obj = {
                    "id": id,
                    "users_custom_expiry_date": data['users_custom_expiry_date'],
                    "action":"update_user_expiry_date"
                }

                let updateExpiryDate = await updateUserExpiryDate(obj);
                if (updateExpiryDate && updateExpiryDate.status == 'success') {
                    setIsSubmitting(false);
                    Swal.fire({
                        title: "Success",
                        text: "Record added successfully.",
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                    navigate('/users');

                } 
                else {
                    setIsSubmitting(false);
                    Swal.fire({
                        title: "Error",
                        text: "Something is wrong. Please try again.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }

            }
            else {
                setIsSubmitting(false);
                Swal.fire({
                    title: "Error",
                    text: "Please enter valid information.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        })();
    }

    return (
    <div>
    <Header />

    <main id="main" className="main">
        <section className="section">
            <div className="row">
                <div className="col-lg-9">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Update User Expiry Date</h5>
                           
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row mb-3">
                                    <label htmlFor="users_custom_expiry_date" className="col-sm-2 col-form-label">Expiry Date</label>
                                    <div className="col-sm-4">
                                        <input type="date" id="users_custom_expiry_date" name="users_custom_expiry_date" {...register('users_custom_expiry_date')} className={`form-control ${errors.users_custom_expiry_date ? 'is-invalid' : ''}`}   />
                                        <div className="invalid-feedback">{errors.users_custom_expiry_date?.message}</div>
                                    </div>
                                </div>
                                
                                <div className="row mb-3">
                                    <div className="col-sm-10 offset-sm-2">
                                        <div className="form-check1">
                                           
                                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>} Update</button>&nbsp;
                                            <button type="reset" className="btn btn-secondary" onClick={()=> navigate('/users')}>Back</button>&nbsp;

                                           
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <Footer />
    </div>
    );
}

export default EditExpiryDate;

