import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

import {
    Header,
    Footer,
   
    useToken
} from "../components";

import { yupResolver } from '@hookform/resolvers/yup';
import Swal from "sweetalert2";
import * as Yup from 'yup';



async function updatePassword(jsonData) {
    return fetch(process.env.REACT_APP_BASE_URL+'kudosy_admin', {
            method: 'PATCH',
            body: JSON.stringify(jsonData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json())
}


async function getUser(credentials) {
   return fetch(process.env.REACT_APP_BASE_URL+'kudosy_admin?email=' + credentials, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json())
}



function ChangePassword() {

    const { id } = useParams();

    // form validation rules 
    const validationSchema = Yup.object().shape({
        old_password: Yup.string().required('Old password is required').min(6, 'Password must be at least 6 characters'),
        new_password: Yup.string().required('New password is required').min(6, 'Password must be at least 6 characters'),
        confirm_password: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('new_password')], 'Confirm password must match with new password')
    });

    const { register, handleSubmit, reset, setValue, getValues, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { errors } = formState;
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false); 

     const [reward, setReward] = useState({});

    useEffect(() => {
        // Fetch post using the postSlug
    }, []);


    function onSubmit(data) {
        setIsSubmitting(true);
        let  userSession  = JSON.parse(localStorage.getItem('token'));
        
        (async () => {
            let getuser = await getUser(userSession.email);
            if (getuser && getuser.count > 0) {
                let userData = getuser.items[0];
                if(userData['password']==data['old_password']) {
                    let obj = {
                        "IndexItem": {
                            "email": userSession.email
                        },
                        "password": data['new_password'].toString()
                        
                    }
                    let saveReward = await updatePassword(obj);
                    if (saveReward && saveReward.status == 'OK') {
                        setIsSubmitting(false);
                        Swal.fire({
                            title: "Success",
                            text: "Password updated successfully.",
                            icon: "success",
                            confirmButtonText: "OK",
                        });
                        navigate('/dashboard');

                    } else {
                        setIsSubmitting(false);
                        Swal.fire({
                            title: "Error",
                            text: "Something is wrong. Please try again.",
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    }

                }
                else {
                    setIsSubmitting(false);
                    Swal.fire({
                        title: "Error",
                        text: "Old password is not correct. Please try again.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }
            }
            
        })();
    }

    return (
    <div>
    <Header />

    <main id="main" className="main">
        <section className="section">
            <div className="row">
                <div className="col-lg-9">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Update Password</h5>
                           
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row mb-3">
                                    <label htmlFor="old_password" className="col-sm-2 col-form-label">Old Password</label>
                                    <div className="col-sm-6">
                                        <input type="password" id="old_password" name="old_password" {...register('old_password')} className={`form-control ${errors.old_password ? 'is-invalid' : ''}`}   />
                                        <div className="invalid-feedback">{errors.old_password?.message}</div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="new_password" className="col-sm-2 col-form-label">New Password</label>
                                    <div className="col-sm-6">
                                        <input type="password" id="new_password" name="new_password" {...register('new_password')} className={`form-control ${errors.new_password ? 'is-invalid' : ''}`}  />
                                        <div className="invalid-feedback">{errors.new_password?.message}</div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="confirm_password" className="col-sm-2 col-form-label">Confirm Password</label>
                                    <div className="col-sm-6">
                                        <input type="password"  id="confirm_password" name="confirm_password" {...register('confirm_password')} className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.confirm_password?.message}</div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-10 offset-sm-2">
                                        <div className="form-check1">
                                           
                                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>} Update</button>&nbsp;
                                            <button type="reset" className="btn btn-secondary" onClick={()=> navigate('/dashboard')}>Back</button>&nbsp;

                                           
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <Footer />
    </div>
    );
}

export default ChangePassword;