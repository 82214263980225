import React, { useEffect } from "react";
import { useParams } from "react-router";

function Footer() {
  let { postSlug } = useParams();

  useEffect(() => {
    // Fetch post using the postSlug
  }, [postSlug]);

  return (
    <div>
     <footer id="footer" className="footer">
      <div className="copyright">
        &copy; Copyright <strong><span>kudosy</span></strong>. All Rights Reserved
      </div>
    
    </footer>
    
    <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
    </div>
      
  );
}

export default Footer;