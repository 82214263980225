import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

import Swal from "sweetalert2";

import './Dashboard.css';

import 'antd/dist/antd.min.css';
import { Pagination } from 'antd';


import {
    Header,
    Footer
   
} from "../components";

//https://www.freakyjolly.com/react-bootstrap-table-next-example-pagination-filter-sorting-export-to-excel/



async function getRewards(keyword, type) {
   
    return await fetch(process.env.REACT_APP_SERVER_BASE_URL+"get_rewards?type="+type+"&search_by="+keyword, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => {
           return data.json()
        })
        .catch(error => {
          console.log("error","error:"+error);
          return "error:"+error;
        });
};


async function deleteReward(udid) {
    return await fetch(process.env.REACT_APP_SERVER_BASE_URL+'delete_kudosy_reward', {
            method: 'POST',
            body: JSON.stringify({"udid":udid,"action":"delete_kudosy_reward"}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json());
};



function Dashboard() {
    const [reward, setReward] = useState();
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(10);
    const [dataisLoaded, setDataisLoaded] = useState(true);
    const [rewardType, setRewardType] = useState('');
    const [searchBy, setSearchBy] = useState('');
    let { postSlug } = useParams();
    const navigate = useNavigate();


  
    const { register, handleSubmit,  formState } = useForm();

    const { errors } = formState;



    useEffect(() => {
        (async () => {
            let rewards = await getRewards(searchBy,rewardType);
            if (rewards && rewards.count > 0) {
                setReward(rewards.items);
                setDataisLoaded(false);
            }
            else {
              setReward(false);
              setDataisLoaded(false);
            }
        })();
    }, [postSlug]);


    function handleDelete(udid) {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showDenyButton: true,
            //showCancelButton: true,
            confirmButtonText: 'Yes Delete',
            denyButtonText: `Don't Delete`,
        }).then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    if (udid && udid !== '') {
                        setDataisLoaded(true);
                        let rewards = await deleteReward(udid);
                        if (rewards && rewards.status == 'success') {
                            Swal.fire('Record deleted successfully!', '', 'success');
                            let rewards = await getRewards(searchBy,rewardType);
                            if (rewards && rewards.count > 0) {
                                setReward(rewards.items);
                                setDataisLoaded(false);
                            } else {
                                setReward(false);
                                setDataisLoaded(false);
                            }

                        } else {
                            Swal.fire('Reward not deleted. Please try again', '', 'error')
                        }
                    }
                })();
            } else if (result.isDenied) {

            }
        })
    }


    function handlePageChange(value,pageSize) {
        console.log("value", value, pageSize)
        if (value <= 1) {
            setMinValue(0);
            setMaxValue(pageSize);
        } else {
            setMinValue((value - 1) * pageSize);
            setMaxValue(Number(value) * pageSize);
        }
    }

    function handleRewardChange(event){
        setRewardType(event.target.value);
        
        (async () => {
            setMinValue(0);
            setDataisLoaded(true);
            let rewards = await getRewards(searchBy,event.target.value);
            if (rewards && rewards.count > 0) {
                setReward(rewards.items);
                setDataisLoaded(false);
            } else {
                setReward(false);
                setDataisLoaded(false);
            }
        })();
       
    };

    function onSubmit(data) {
        //if (data && data.query !== '') {
            setSearchBy(data.query);
           
            (async () => {
                setMinValue(0);
                setDataisLoaded(true);
                let rewards = await getRewards(data.query,rewardType);
                if (rewards && rewards.count > 0) {
                    setReward(rewards.items);
                    setDataisLoaded(false);
                } else {
                    setReward(false);
                    setDataisLoaded(false);
                }
            })();
        //}
    }

    return (
        <div>
    <Header />
    
    <main id="main" className="main">
        <div className="pagetitle">
            <h1>Dashboard</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">Dashboard</li>
                </ol>
            </nav>
        </div>
        <section className="section dashboard">
            
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body">

                                  <div className="row">
                                    <div className="col-md-2"><h5 className="card-title">Manage Rewards</h5></div>
                                    <div className="col-md-4">
                                     <select name="reward_type" id="reward_type" className="form-control search-form" onChange={handleRewardChange}>
                                            <option value="">Select Category</option>
                                            <option value="Experience">Experience</option>
                                            <option value="Things">Things</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="search-bar">
                                       
                                        <form className="search-form d-flex align-items-center" onSubmit={handleSubmit(onSubmit)}>
                                          <input type="text" name="query" id="query"  {...register('query')} className={`${errors.query ? 'is-invalid' : ''}`}  placeholder="Search" title="Enter search keyword" />
                                          <button type="submit" title="Search"><i className="bi bi-search"></i></button>
                                        </form>
                                        <div className="invalid-feedback">{errors.query?.message}</div>
                                      </div>
                                    </div>
                                    <div className="col-md-2 text-end"><br /><button type="button" className="btn btn-secondary btn-sm" onClick={() => navigate('/add_reward')}><i className="bi bi-pencil-square me-1"></i> Add New Reward</button></div>
                                  </div>   

                                  <br />
                                    <table className="table table-borderless datatable">
                                        <thead>
                                            <tr>
                                                <th scope="col">Title</th>
                                                <th scope="col">Kudo Cost</th>
                                                <th scope="col">Category</th>
                                                <th scope="col">Amazon Link</th>
                                                <th scope="col">Display Order</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { dataisLoaded ?
                                            <tr>
                                                <td colSpan="7" align="center"><br />
                                                    <div className="spinner-grow text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-secondary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-success" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-danger" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-warning" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-info" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-light" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-dark" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            : ''
                                            }

                                          {reward &&
                                              reward.length > 0 &&
                                              reward.slice(minValue, maxValue).map((item, index) => (
                                            
                                            //setCounter(counter+1)
                                            <tr key={index}>
                                                <td>{item.title}</td>
                                                <td>{item.kudo_cost}</td>
                                                <td>{item.category}</td>
                                                <td><a href={item.amazon_link} target="_blank" rel="noreferrer" className="text-primary">{item.amazon_link}</a></td>
                                                <td>{(item.display_order==9999)?'':item.display_order}</td>
                                                <td><span className="badge bg-success">{item.is_active==1?'Active':'Deactive'}</span></td>
                                                <td><a onClick={()=> navigate('/edit_reward/'+item.udid)} className="btn btn-secondary btn-sm" title="Edit"><i className="bi bi-pencil-square"></i></a>&nbsp;
                                                <a className="btn btn-danger btn-sm" title="Delete" onClick={e => handleDelete(item.udid)}><i className="bi bi-trash"></i></a></td>
                                            </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                  
                                        <div style={{ display: 'block', width: 700, padding: 30 }}>
                                          { reward ?
                                          <Pagination defaultCurrent={1} defaultPageSize={10} onChange={handlePageChange} total={reward.length} />
                                          : ''
                                        }
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>
    </main>
    <Footer />
</div>
    );
}

export default Dashboard;