import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { useForm } from "react-hook-form";

import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

import './Dashboard.css';

import 'antd/dist/antd.min.css';
import { Pagination } from 'antd';


import {
    Header,
    Footer
   
} from "../components";

//https://www.freakyjolly.com/react-bootstrap-table-next-example-pagination-filter-sorting-export-to-excel/



async function getUsers(obj) {
   
    return await fetch(process.env.REACT_APP_SERVER_BASE_URL+"get_users_with_pagination", {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => {
           return data.json()
        })
        .catch(error => {
          console.log("error","error:"+error);
          return "error:"+error;
        });
};


async function deleteUser(udid) {
    return await fetch(process.env.REACT_APP_SERVER_BASE_URL+'delete_kudosy_account', {
            method: 'POST',
            body: JSON.stringify({"user_id":udid,"action":"delete_account"}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json());
};



function Users() {
    const [users, setUsers] = useState();
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(10);
    const [dataisLoaded, setDataisLoaded] = useState(true);
    let { postSlug } = useParams();

    const [totalRecords, setTotalRecords] = useState(0);
    const [defaultPage, setDefaultPage] = useState(0);
    const [defaultPageSize, setDefaultPageSize] = useState(10);
    const [searchkeyword, setSearchkeyword] = useState('');


    const { register, handleSubmit,  formState } = useForm();

    const { errors } = formState;
    const navigate = useNavigate();



    useEffect(() => {
        (async () => {
            let obj = {
                keyword:searchkeyword,
                page_limit: 0,
                pageSize: 10,
            }
            let users = await getUsers(obj);
            if (users && users.count > 0) {
                setTotalRecords(users.count);
                setUsers(users.items);
                setDataisLoaded(false);
            }
            else {
              setUsers(false);
              setDataisLoaded(false);
            }
        })();
    }, [postSlug]);


    function handleDelete(udid) {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showDenyButton: true,
            //showCancelButton: true,
            confirmButtonText: 'Yes Delete',
            denyButtonText: `Don't Delete`,
        }).then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    if (udid && udid !=='') {
                        setDataisLoaded(true);
                        let delUser = await deleteUser(udid);
                        if (delUser && delUser.status == 'success') {
                            Swal.fire('Record deleted successfully!', '', 'success');
                            let obj = {
                                keyword:searchkeyword,
                                page_limit: 0,
                                pageSize: 10,
                            }
                            let users = await getUsers(obj);
                            
                            if (users && users.count > 0) {
                                setTotalRecords(users.count);
                                setUsers(users.items);
                                setDataisLoaded(false);
                            } else {
                                setUsers(false);
                                setDataisLoaded(false);
                            }

                        } else {
                            Swal.fire('User not deleted. Please try again', '', 'error')
                        }
                    }
                })();
            } else if (result.isDenied) {

            }
        })
    }


    async function handlePageChange(value,pageSize) {
        console.log("value", value, pageSize);
        setDefaultPage(value);
        setDefaultPageSize(pageSize);

        let obj = {
            keyword:searchkeyword,
            page_limit: value-1,
            pageSize: pageSize,
        }
        let users = await getUsers(obj);
        
        if (users && users.count > 0) {
            setTotalRecords(users.count);
            setUsers(users.items);
            setDataisLoaded(false);
        } else {
            setUsers(false);
            setDataisLoaded(false);
        }



        /*if (value <= 1) {
            setMinValue(0);
            setMaxValue(pageSize);
        } else {
            setMinValue((value - 1) * pageSize);
            setMaxValue(Number(value) * pageSize);
        }*/
    }

    function onSubmit(data) {
        //if (data && data.query !== '') {
            (async () => {
                setMinValue(0);
                setDataisLoaded(true);
                let obj = {
                    keyword:data.query,
                    page_limit: 0,
                    pageSize: 10,
                }
                setSearchkeyword(data.query);
                let users = await getUsers(obj);
                if (users && users.count > 0) {
                    setTotalRecords(users.count);
                    setUsers(users.items);
                    setDataisLoaded(false);
                } else {
                    setUsers(false);
                    setDataisLoaded(false);
                }
            })();
        //}
    }

    return (
        <div>
    <Header />
    
    <main id="main" className="main">
        <div className="pagetitle">
            <h1>Dashboard</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">Users</li>
                </ol>
            </nav>
        </div>
        <section className="section dashboard">
            
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body">

                                  <div className="row">
                                    <div className="col-md-4"><h5 className="card-title">Manage Users ({totalRecords})</h5></div>
                                    <div className="col-md-4">
                                      <div className="search-bar">
                                        <form className="search-form d-flex align-items-center" onSubmit={handleSubmit(onSubmit)}>
                                          <input type="text" name="query" id="query"  {...register('query')} className={`${errors.query ? 'is-invalid' : ''}`}  placeholder="Search" title="Enter search keyword" />
                                          <button type="submit" title="Search"><i className="bi bi-search"></i></button>
                                        </form>
                                        <div className="invalid-feedback">{errors.query?.message}</div>
                                      </div>
                                    </div>
                                    
                                  </div>   

                                  <br />
                                    <table className="table table-borderless datatable">
                                        <thead>
                                            <tr>
                                                <th scope="col">Email</th>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Created</th>
                                                <th scope="col">Last App Open</th>

                                                <th scope="col">App Version</th>
                                                <th scope="col">Device Name</th>
                                                <th scope="col">Device Version</th>
                                                <th scope="col">Custom Expiry Date</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { dataisLoaded ?
                                            <tr>
                                                <td colSpan="10" align="center"><br />
                                                    <div className="spinner-grow text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-secondary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-success" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-danger" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-warning" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-info" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-light" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-dark" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            : ''
                                            }

                                          {users &&
                                              users.length > 0 &&
                                              users.map((item, index) => (
                                            
                                            //setCounter(counter+1)
                                            <tr key={index}>
                                                <td>{item.email}</td>
                                                <td>{item.first_name}</td>
                                                <td>{item.last_name}</td>
                                                <td>{item.createdAt}</td>
                                                <td>{item.lastOpenDate}</td>
                                                <td>{item.app_version}</td>
                                                <td>{item.device_name}</td>
                                                <td>{item.device_version}</td>
                                                <td>{item.users_custom_expiry_date} <a onClick={()=> navigate('/edit_expiry_date/'+item.id)}  title="Edit Expiry Date"><i class="bi bi-pencil-square"></i></a></td>
                                                <td><a className="btn btn-danger btn-sm" href="javascript:;"  title="Delete" onClick={e => handleDelete(item.id)}><i className="bi bi-trash"></i></a></td>
                                                
                                            </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                  
                                        <div style={{ display: 'block', width: 700, padding: 30 }}>
                                          { users ?
                                          <Pagination defaultCurrent={defaultPage} defaultPageSize={defaultPageSize} onChange={handlePageChange} total={totalRecords} />
                                          : ''
                                        }
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>
    </main>
    <Footer />
</div>
    );
}

export default Users;
