import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";


import { useForm } from "react-hook-form";

import {
    Header,
    Footer,

    useToken
} from "../components";

import { yupResolver } from '@hookform/resolvers/yup';
import Swal from "sweetalert2";
import * as Yup from 'yup';

const defaultSrc ="https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";


async function addNewReward(jsonData) {
    return fetch(process.env.REACT_APP_SERVER_BASE_URL + 'save_kudosy_rewards', {
            method: 'POST',
            body: JSON.stringify(jsonData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json())
}

async function uploadImage(jsonData) {
    return fetch(process.env.REACT_APP_BASE_URL + 'photo_upload', {
            method: 'POST',
            body: JSON.stringify(jsonData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json())
}


function broofa() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}


function AddReward() {

    // form validation rules 
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        //description: Yup.string().required('Description is required'),
        kudo_cost: Yup.string().required('Kudo Cost is required'),

        amazon_link: Yup.string().when("category", {
            is: 'Things',
            then: Yup.string().required("Please enter Amazon link")
        }),
        category: Yup.string().required('Please Select a category')


    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [kudo_cost, setKudoCost] = useState();
    const [amazon_link, setAmazonLink] = useState();
    const [displayOrder, setDisplayOrder] = useState(9999);
    const [category, setCategory] = useState();
    const [rewardImage, setRewardImage] = useState();
    const [is_active, setIsActive] = useState(true);


    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [image, setImage] = useState(defaultSrc);
    const [cropData, setCropData] = useState();
    const [cropper, setCropper] = useState();





    let { postSlug } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch post using the postSlug
    }, [postSlug]);


    
    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
        }
    };


    const onSubmit = async event => {
        console.log(cropData);

        let obj = {
            "udid": broofa(),
            "title": title,
            "description": description,
            "kudo_cost": kudo_cost,
            "amazon_link": amazon_link,
            "image_url": "null",
            "category": category,
            "display_order": (displayOrder)?displayOrder:9999,
            "action":"save_kudosy_rewards",
            "is_active": (is_active) ? 1 : 0
        }

         if(cropData) {
            setIsSubmitting(true);
            let imgObj ={"imgBody":cropData,"action":"kudosy_img"};
            let saveImage = await uploadImage(imgObj);
            console.log("saveImage",saveImage)
            if (saveImage && saveImage.status == 'OK') {
                obj.image_url = saveImage.imageName;

                let saveReward = await addNewReward(obj);
                if (saveReward && (saveReward.status == 'OK' || saveReward.status == 'success')) {
                    setIsSubmitting(false);
                    Swal.fire({
                        title: "Success",
                        text: "Record added successfully.",
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                    navigate('/dashboard');

                } else {
                    setIsSubmitting(false);
                    Swal.fire({
                        title: "Error",
                        text: "Something is wrong. Please try again.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }

            }
            else {
                setIsSubmitting(false);
                Swal.fire({
                    title: "Error",
                    text: "Image not uploaded. Please try again.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
         }
         else {
            setIsSubmitting(true);
            let saveReward = await addNewReward(obj);
            if (saveReward && (saveReward.status == 'OK' || saveReward.status == 'success')) {
                setIsSubmitting(false);
                Swal.fire({
                    title: "Success",
                    text: "Record added successfully.",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                navigate('/dashboard');

            } else {
                setIsSubmitting(false);
                Swal.fire({
                    title: "Error",
                    text: "Something is wrong. Please try again.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
         }
        //console.log(URL.createObjectURL(rewardImage));
        /*

        

        */
    }

    return (
        <div>
            <Header />
            <main id="main" className="main">
                <section className="section">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Add Reward</h5>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row mb-3">
                                            <div className="col-sm-12">
                                                <label htmlFor="inputText" className="col-form-label">Title</label>
                                                <input type="text" name="title" {...register('title')} className={`form-control ${errors.title ? 'is-invalid' : '' }`} id="inputText" onChange={e=> setTitle(e.target.value)} />
                                                <div className="invalid-feedback">{errors.title?.message}</div>
                                             
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-sm-12">
                                                <label htmlFor="description" className="col-form-label">Description</label>
                                                <textarea name="description" {...register('description')} className={`form-control ${errors.description ? 'is-invalid' : '' }`} placeholder="Description" id="description" onChange={e=> setDescription(e.target.value)} ></textarea>
                                                <div className="invalid-feedback">{errors.description?.message}</div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            
                                            <div className="col-sm-12">
                                                <label htmlFor="kudo_cost" className="col-form-label">Kudo Cost</label>
                                                <input type="number" name="kudo_cost" {...register('kudo_cost')} className={`form-control ${errors.kudo_cost ? 'is-invalid' : '' }`} id="kudo_cost" onChange={e=> setKudoCost(e.target.value)} />
                                                <div className="invalid-feedback">{errors.kudo_cost?.message}</div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-sm-12">
                                                <label htmlFor="category" className="col-form-label">Category</label>
                                                <select name="category" id="category" {...register('category')} className={`form-select ${errors.category ? 'is-invalid' : '' }`} aria-label="State" onChange={e=> setCategory(e.target.value)} >
                                                    <option value="">--Select Category--</option>
                                                    <option value="Experience">Experience</option>
                                                    <option value="Things">Things</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.category?.message}</div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                             <div className="col-sm-12">
                                                 <label htmlFor="amazon_link" className="col-form-label">Amazon link</label>
                                                <input type="text" name="amazon_link" {...register('amazon_link')} className={`form-control ${errors.amazon_link ? 'is-invalid' : '' }`} id="amazon_link" onChange={e=> setAmazonLink(e.target.value)} />
                                                <div className="invalid-feedback">{errors.amazon_link?.message}</div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            
                                            <div className="col-sm-12">
                                                <label htmlFor="reward_image" className="col-form-label">Reward Image</label>
                                                <input type="file" name="reward_image" {...register('reward_image')} className={`form-control ${errors.reward_image ? 'is-invalid' : '' }`} id="reward_image" accept="image/*" onChange={onChange} />
                                                <div className="invalid-feedback">{errors.reward_image?.message}</div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                             <div className="col-sm-4">
                                                 <label htmlFor="display_order" className="col-form-label">Display Order</label>
                                                <input type="number" name="display_order" {...register('display_order')} className={`form-control ${errors.display_order ? 'is-invalid' : '' }`} id="display_order" onChange={e=> setDisplayOrder(e.target.value)} />
                                                <div className="invalid-feedback">{errors.display_order?.message}</div>
                                            </div>
                                        </div>
                                        <div className="row mb-3"></div>
                                        <div className="row mb-3">
                                             <div className="col-sm-12">

                                                    <input id="is_active" checked name="is_active" {...register('is_active')} className={`form-check-input ${errors.is_active ? 'is-invalid' : '' }`} type="checkbox" onChange={e=> setIsActive(e.target.checked )} />
                                                    <label className="form-check-label" htmlFor="is_active">
                                                        &nbsp;Is Active
                                                    </label>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-sm-12">
                                                    <button type="submit" disabled={isSubmitting} className="btn btn-primary">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>} Save</button>&nbsp;&nbsp;
                                                    <button type="reset" className="btn btn-secondary" onClick={()=> navigate('/dashboard')}>Back</button>&nbsp;
                                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                              
                                <Cropper
                                      style={{ height: 400, width: "100%" }}
                                      zoomTo={0.5}
                                      initialAspectRatio={1}
                                      preview=".img-preview"
                                      src={image}
                                      viewMode={1}
                                      minCropBoxHeight={10}
                                      minCropBoxWidth={10}
                                      background={false}
                                      responsive={true}
                                      autoCropArea={1}
                                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                      onInitialized={(instance) => {
                                        setCropper(instance);
                                      }}
                                      guides={true}
                                    />
                            </div>
                            <div className="col-lg-6">
                                {image && (
                                    <div>
                                        <b>Preview</b>
                                        <div className="img-preview" style={{ width: "100%", float: "left", height: "300px" }} />
                                    </div>
                                      )}
                            </div>
                            <div className="col-lg-6">
                                    {cropData && (
                                    <div>
                                        <b>Crop</b>
                                        <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                                        
                                     </div>
                                      )}
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <button className="btn btn-secondary" style={{ float: "left" }} onClick={getCropData}>
                                        Crop Image
                                    </button>
                                </div>
                            </div>
                            </div>
                            <br style={{ clear: "both" }} />
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default AddReward;