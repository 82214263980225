import React from 'react';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import reportWebVitals from './reportWebVitals';

import {
    Login,
    Dashboard,
    AddReward,
    EditReward,
    ChangePassword,
    Users,
    FeedBacks,
    EditExpiryDate
} from "./pages";

import {
    useToken
} from "./components";



function App() {

  const { token, setToken } = useToken();
  //console.log("token",token)
  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <Router>
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/add_reward" element={<AddReward />} />
      <Route path="/edit_reward/:id" element={<EditReward />} />
      <Route path="/users" element={<Users />} />
      <Route path="/feedback" element={<FeedBacks />} />
      <Route path="/edit_expiry_date/:id" element={<EditExpiryDate />} />
    </Routes>
  </Router>
  );
}

export default App;