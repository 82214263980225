import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from "sweetalert2";
import * as Yup from 'yup';



import logo from '../logo.png';


async function loginUser(credentials) {
    return fetch(`${process.env.REACT_APP_BASE_URL}`+'kudosy_admin?email=' + credentials, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json())
}


function Login({ setToken }) {

    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().email('Invalid username format. should be a proper email address.').required('Username is required'),
        password: Yup.string().required('Password is required')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = async e => {
        // e.preventDefault();
        setIsSubmitting(true);
        let token = await loginUser(username);
        if (token && token.count > 0) {
            let userData = token.items[0];
            if (userData['password'] == password) {
                setIsSubmitting(false);
                Swal.fire({
                  title: "Success",
                  text: "Welcome "+userData['full_name']+"!",
                  icon: "success",
                  confirmButtonText: "OK",
                });
                setToken(userData);
            }
            else {
                setIsSubmitting(false);
                Swal.fire({
                  title: "Error",
                  text: "The password doesn't match. Please try again.",
                  icon: "error",
                  confirmButtonText: "OK",
                });
            }
        }
        else {
          setIsSubmitting(false);
          Swal.fire({
                  title: "Error",
                text: "The email and password are not valid. Please try again..",
                icon: "error",
                confirmButtonText: "OK",
              });
        }

        //
    }

    let { postSlug } = useParams();

    useEffect(() => {
        // Fetch post using the postSlug
    }, [postSlug]);

    let url = "";

    return (
        <main>
        <div className="container">

          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                  <div className="d-flex justify-content-center py-4">
                    <a href={url} className="logo d-flex align-items-center w-auto">
                     <img src={logo} className="App-logo" alt="logo" />
                      <span className="d-none d-lg-block">kudosy</span>
                    </a>
                  </div>

                  <div className="card mb-3">

                    <div className="card-body">

                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                        <p className="text-center small">Enter your username & password to login</p>
                      </div>

                      <form className="row g-3 needs-validation" onSubmit={handleSubmit(onSubmit)}>

                        <div className="col-12">
                          <label htmlFor="yourUsername" className="form-label">Username</label>
                          <div className="input-group has-validation">
                            <span className="input-group-text" id="inputGroupPrepend">@</span>
                            <input type="text" name="username" {...register('username')} className={`form-control ${errors.username ? 'is-invalid' : ''}`} id="yourUsername" onChange={e => setUserName(e.target.value)} />
                            <div className="invalid-feedback">{errors.username?.message}</div>
                          </div>
                        </div>

                        <div className="col-12">
                          <label htmlFor="yourPassword" className="form-label">Password</label>
                          <input type="password" name="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="yourPassword" onChange={e => setPassword(e.target.value)} />
                          <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>

                        <div className="col-12">
                          <button className="btn btn-primary w-100" disabled={isSubmitting} type="submit">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>} Login</button>
                        </div>
                        <div className="col-12">
                         {errors.apiError &&
                            <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>
                        }
                        </div>
                      </form>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>

        </div>
  </main>
    );
}

export default Login;

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};