import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

import './Dashboard.css';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import {
    Header,
    Footer,
   
    useToken
} from "../components";

import { yupResolver } from '@hookform/resolvers/yup';
import Swal from "sweetalert2";
import * as Yup from 'yup';

const defaultSrc ="https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


async function updateReward(jsonData) {
    return fetch(process.env.REACT_APP_SERVER_BASE_URL+'update_kudosy_rewards', {
            method: 'POST',
            body: JSON.stringify(jsonData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json())
}

async function uploadImage(jsonData) { console.log("sdjhagdjhags");
    return fetch(process.env.REACT_APP_BASE_URL + 'photo_upload', {
            method: 'POST',
            body: JSON.stringify(jsonData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json())
}

async function getReward(udid) {
   return await fetch(process.env.REACT_APP_SERVER_BASE_URL+'get_kudosy_reward/'+udid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(data => data.json());
}


function broofa() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
}


function EditReward() {

    const { id } = useParams();

    // form validation rules 
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        //description: Yup.string().required('Description is required'),
        kudo_cost: Yup.string().required('Kudo Cost is required'),
        amazon_link: Yup.string().when("category", {
            is: 'Things',
            then: Yup.string().required("Please enter Amazon link")
          }),
        category: Yup.string().required('Please Select a category')
        
    });

    const { register, handleSubmit, reset, setValue, getValues, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { errors } = formState;

    //let { postSlug, id } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true); 
    const [isSubmitting, setIsSubmitting] = useState(false); 

    

    const [image, setImage] = useState();
    const [cropData, setCropData] = useState();
    const [oldImage, setOldImage] = useState();
    const [cropper, setCropper] = useState();


    useEffect(() => {
        // Fetch post using the postSlug
        setIsSubmitting(true);
        (async () => {
            if(id && id!=='') {
                let rewards = await getReward(id);
                if (rewards && rewards.count > 0) {
                    let getItems = rewards.items[0];
                    console.log("getItems",getItems);
                    const fields = ['udid','title', 'description', 'kudo_cost', 'category','amazon_link', 'is_active', 'display_order'];
                    fields.forEach(field => setValue(field, getItems[field]));
                    setOldImage(getItems['image_url']);
                    setImage('https://kudosy.s3.amazonaws.com/images/'+getItems['image_url'])
                    //setReward(reward);
                    setIsLoading(false);
                    setIsSubmitting(false);
                }
            }
        })();
    }, []);


    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
        }
    };



    function onSubmit(data) {
        setIsSubmitting(true);
        (async () => {
            let obj = {
                "udid": id,
                "title": data.title,
                "description": data.description,
                "kudo_cost": data.kudo_cost,
                "amazon_link": data.amazon_link,
                "category": data.category,
                "display_order": (data.display_order)?data.display_order:9999,
                "is_active": (data.is_active)?1:0,
                "image_url":oldImage,
                "action":"update_kudosy_rewards"
            }
            if(cropData) {
                let imgObj ={"imgBody":cropData,"action":"kudosy_img"};
                let saveImage = await uploadImage(imgObj);
                if (saveImage && saveImage.status == 'OK') {
                    obj.image_url = saveImage.imageName;

                    let saveReward = await updateReward(obj);
                    if (saveReward && saveReward.status == 'success') {
                        setIsSubmitting(false);
                        Swal.fire({
                            title: "Success",
                            text: "Record added successfully.",
                            icon: "success",
                            confirmButtonText: "OK",
                        });
                        navigate('/dashboard');

                    } 
                    else {
                        setIsSubmitting(false);
                        Swal.fire({
                            title: "Error",
                            text: "Something is wrong. Please try again.",
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                    }
                }
                else {
                    setIsSubmitting(false);
                    Swal.fire({
                        title: "Error",
                        text: "Image not uploaded. Please try again.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }
             }
             else {
                let saveReward = await updateReward(obj);
                if (saveReward && saveReward.status == 'success') {
                    setIsSubmitting(false);
                    Swal.fire({
                        title: "Success",
                        text: "Record added successfully.",
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                    navigate('/dashboard');

                } else {
                    setIsSubmitting(false);
                    Swal.fire({
                        title: "Error",
                        text: "Something is wrong. Please try again.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }
            }
        })();
    }

    return (
    <div>
    <Header />
   
    <main id="main" className="main">
        <section className="section">
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Edit Reward</h5>
                            {isLoading && <div><span className="spinner-border spinner-border-sm mr-1"></span> loading...</div>}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <label htmlFor="inputText" className="col-form-label">Title</label>
                                            <input type="text" name="title" {...register('title')} className={`form-control ${errors.title ? 'is-invalid' : ''}`}  id="inputText" />
                                            <div className="invalid-feedback">{errors.title?.message}</div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <label htmlFor="description" className="col-form-label">Description</label>
                                        <textarea name="description"  {...register('description')} className={`form-control ${errors.description ? 'is-invalid' : ''}`} placeholder="Description" id="description"></textarea>
                                        <div className="invalid-feedback">{errors.description?.message}</div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <label htmlFor="kudo_cost" className="col-form-label">Kudo Cost</label>
                                        <input type="number" name="kudo_cost" {...register('kudo_cost')} className={`form-control ${errors.kudo_cost ? 'is-invalid' : ''}`} id="kudo_cost"/>
                                        <div className="invalid-feedback">{errors.kudo_cost?.message}</div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    
                                    <div className="col-sm-12">
                                        <label htmlFor="category" className="col-form-label">Category</label>
                                        <select name="category" id="category" {...register('category')} className={`form-select ${errors.category ? 'is-invalid' : ''}`} aria-label="State" >
                                            <option value="">--Select Category--</option>
                                            <option value="Experience">Experience</option>
                                            <option value="Things">Things</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.category?.message}</div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <label htmlFor="amazon_link" className="col-form-label">Amazon link</label>
                                        <input type="text" name="amazon_link" {...register('amazon_link')} className={`form-control ${errors.amazon_link ? 'is-invalid' : ''}`} id="amazon_link" />
                                        <div className="invalid-feedback">{errors.amazon_link?.message}</div>
                                    </div>
                                </div>

                               
                                 <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <label htmlFor="reward_image" className="col-form-label">Reward Image</label>
                                        <input type="file" name="reward_image" {...register('reward_image')}  className={`form-control ${errors.reward_image ? 'is-invalid' : ''}`} id="reward_image" accept="image/*" onChange={onChange}/>
                                        <div className="invalid-feedback">{errors.reward_image?.message}</div>
                                    </div>
                                </div>

                                 <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <label htmlFor="display_order" className="col-form-label">Display Order</label>
                                        <input type="text" name="display_order" {...register('display_order')} className={`form-control ${errors.display_order ? 'is-invalid' : ''}`} id="display_order" />
                                        <div className="invalid-feedback">{errors.display_order?.message}</div>
                                    </div>
                                </div>
                                
                               <div className="row mb-3"></div>
                                        <div className="row mb-3">
                                             <div className="col-sm-12">
                                            <input  id="is_active"  name="is_active"  {...register('is_active')} className={`form-check-input ${errors.is_active ? 'is-invalid' : ''}`} type="checkbox" />
                                            <label className="form-check-label" htmlFor="is_active">
                                                &nbsp;Is Active
                                            </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                        <div className="col-sm-12">
                                        
                                        <button type="submit"  disabled={isSubmitting} className="btn btn-primary">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>} Update</button>&nbsp;
                                        <button type="reset" className="btn btn-secondary" onClick={()=> navigate('/dashboard')}>Back</button>&nbsp;
                                        
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                               {image && (
                                    <div>
                                <Cropper
                                      style={{ height: 400, width: "100%" }}
                                      zoomTo={0.5}
                                      initialAspectRatio={1}
                                      preview=".img-preview"
                                      src={image}
                                      viewMode={1}
                                      minCropBoxHeight={10}
                                      minCropBoxWidth={10}
                                      background={false}
                                      responsive={true}
                                      autoCropArea={1}
                                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                      onInitialized={(instance) => {
                                        setCropper(instance);
                                      }}
                                      guides={true}
                                    />
                                    </div>
                                      )}
                            </div>
                            <div className="col-lg-6">
                                {image && (
                                    <div>
                                        <b>Preview</b>
                                        <div className="img-preview" style={{ width: "100%", float: "left", height: "300px" }} />
                                    </div>
                                      )}
                            </div>
                            <div className="col-lg-6">
                                    {cropData && (
                                    <div>
                                        <b>Crop</b>
                                        <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                                        
                                     </div>
                                      )}
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    {image && (
                                    <div>
                                    <button className="btn btn-secondary" style={{ float: "left" }} onClick={getCropData}>
                                        Crop Image
                                    </button>
                                    </div>
                                      )}
                                </div>
                            </div>
                            </div>
                            <br style={{ clear: "both" }} />
                        </div>
            </div>
        </section>
    </main>
    <Footer />
    </div>
    );
}

export default EditReward;